import React from 'react'
import imgCon from '../assets/img-construction.svg';
function UnderConstruction() {
  return (
    <div className='flex flex-col items-center -space-y-12 mt-72'>
        
        <div className='max-w-screen-sm'>
        <h1 className='text-5xl text-myWhite font-bold text-center'>Page Under Construction</h1>
        <p className='text-myWhite text-center px-8'>Stay tuned for updates, and feel free to reach out if you'd like to collaborate or learn more in the meantime!</p>
        </div>
        <img src={imgCon} alt="" className='w-full opacity-50' />
    </div>
  )
}

export default UnderConstruction