import React from 'react'
import Hero from '../components/Hero'
import Info from '../components/Info'
import HrLine from '../assets/hr-line.png'
import Skill from '../components/Skill'
import UnderConstruction from '../components/UnderConstruction'
import Projects from '../components/Projects'

function Home() {
  return (
    <div className='bg-gradient-to-r from-gray-900 to-black pb-96 '>
    <div className=" bg-[url('/src/assets/bg-hero-A.png')] bg-right-top bg-no-repeat  bg-[length:900px] ">
      <Hero/>
      <hr className='mx-8 opacity-0 md:hidden'/> 
<img src={HrLine} alt="" className='w-full -my-40 h-96 md:h-auto'/>
<div className=' md:-translate-y-0'>
      <Info/>
      <Skill/>
      <Projects/>
      <UnderConstruction/>
      
      </div>
    </div>
    </div>
  )
}

export default Home
