import React from 'react'
import Info from '../components/Info'
import TimeLine from '../components/TimeLine'

function About() {
  return (
    <div className='flex justify-center items-center  bg-gradient-to-r from-gray-900 to-black bg-cover'>
    <div className=' max-w-screen-lg px-8 md:px-0 '>
        <Info/>
        <h1 className='text-myWhite text-4xl font-bold uppercase text-center pt-28 pb-16'>TimeLine</h1>
        <div className=''></div>
        <TimeLine/>
    </div>
    </div>
  )
}

export default About