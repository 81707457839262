import React from 'react'
import './buttonA.css';
import rightArrow from '../assets/i-rightArrow.svg';

function ButtonA({text}) {
  return (
    <div className='ButtonA '>
        <button className='flex flex-row justify-center items-center gap-1 py-2 px-3'>
            <p>{text}</p>
            <img src={rightArrow} alt="rightArrow" className=' size-4' />
        </button>
    </div>
  )
}

export default ButtonA