import React from 'react'
import iReact from '../assets/i-react.png';
import iTailwind from '../assets/i-tailwind.png';
import iJs from '../assets/i-js.png';
import iHtml from '../assets/i-html.png';
import iCss from '../assets/i-css.png';
import iBoostrap from '../assets/i-boostrap.png';
import iNode from '../assets/i-node.png';
import iC from '../assets/i-c.png';
import iCplus from '../assets/i-c++.png';
import iFigma from '../assets/i-figma.png';
import iPs from '../assets/i-ps.png';
import iAi from '../assets/i-ai.png';
import iCapcut from '../assets/i-capcut.png';
import iVscode from '../assets/i-vscode.png';
import iGithub from '../assets/i-github.png';


function Skill() {
  return (
    <div className=' flex flex-row justify-center items-baseline px-8 lg:px-4 space-x-1 items-center md:ml-20 mt-20 md:mt-56'>
      <div className='max-w-[1024px]  '>
      {/* <div className=' opacity-50 my-10 flex flex-col items-end'>
        <h1 className='text-myWhite font-medium uppercase'>my skills</h1>
        <p className='bg-myWhite h-0.5 w-1/2'></p>
        </div> */}
        <div className='flex flex-col md:flex-row justify-between items-center  md:space-x5-4'>
            <div className='w-full md:w-1/2'>
                <h1 className='text-myWhite text-2xl ms:text-3xl lg:text-4xl pb- sm:pb-9 mt-10 md:mt-0 mb-2 md:mb-0'>An Overview of My Design Skills</h1>
                <p className='text-myWhite text-xs sm:text-sm lg:text-base text-justify  opacity-75'>My design expertise encompasses a broad range of disciplines, each contributing to my ability to deliver impactful and effective solutions. I am dedicated to applying thoughtful strategies and creative approaches to address complex challenges. My commitment to excellence is reflected in my approach to each project, where I focus on understanding client needs and delivering solutions that exceed expectations. Continuous learning and adaptation are key to my process, ensuring that my work remains relevant and high-quality.</p>
            </div>
            <div className='flex flex-row flex-wrap justify-center bg-gradient-to-r from-gray-800 to-slate-950 bg-opacity-65  rounded-lg ml-0 md:ml-5 mt-8 md:mt-0 lg:justify-start items-center w-fit md:w-1/2  '>
                <img src={iReact} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iNode} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iTailwind} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iJs} alt="" className='p-1 sm:p-4 size-8 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iHtml} alt="" className='p-1 sm:p-4 size-11 sm:size-16 md:size-24 _blue-glow'/>
                <img src={iCss} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iBoostrap} alt="" className='p-1 sm:p-4 size-12 sm:size-16 md:size-24 _blue-glow'/>
                <img src={iFigma} alt="" className='p-1 sm:p-4 size-8 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iPs} alt="" className='p-1 sm:p-4 size-12 sm:size-16 md:size-24 _blue-glow'/>
                <img src={iAi} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iCapcut} alt="" className='p-1 sm:p-4 size-9 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iC} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iCplus} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iVscode} alt="" className='p-1 sm:p-4 size-10 sm:size-16 md:size-20 _blue-glow'/>
                <img src={iGithub} alt="" className='p-1 sm:p-4 size-12 sm:size-16 md:size-28 _blue-glow'/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Skill
