// src/data/UXUI.js
const uxuiProjects = [
    {
      id: 'uxui1', // Unique ID for the project
      title: 'UX Research',
      category: 'Research',
      year: 2023,
      longDescription: 'Conducted extensive user research to gather insights on user behaviors and preferences, which informed the design process of our latest product.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
    {
      id: 'uxui2', // Unique ID for the project
      title: 'Prototyping',
      category: 'Design',
      year: 2023,
      longDescription: 'Created high-fidelity prototypes for user testing using tools like Figma and Adobe XD, focusing on user flows and interactions.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
  ];
  
  export default uxuiProjects;
  