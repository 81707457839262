// src/data/RecentProjects.js
const recentProjects = [
    {
      id: 'recent1', // Unique ID for the project
      title: 'Project A',
      category: 'Web Development',
      year: 2023,
      longDescription: 'This project involved building a full-stack web application using React and Node.js. It includes user authentication, a dynamic dashboard, and real-time data updates.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
    {
      id: 'recent2', // Unique ID for the project
      title: 'Project B',
      category: 'Mobile App',
      year: 2022,
      longDescription: 'A mobile application developed for iOS and Android platforms using React Native. The app features an intuitive user interface and seamless performance.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
  ];
  
  export default recentProjects;
  