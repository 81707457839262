import React from "react";
import './hero.css';

import ButtonA from "./ButtonA";

function Hero() {
  return (
    <div className="Hero  md:pl-20 h-screend">
      <div className="w-full h-full hero-blob  ">
      <div className="w-full opacity-30">
       <a href="/"><h1 className='flex justify-center items-center pt-6'><span className='_ushan'>Ushan</span><span className='_kamesh'>Kamesh</span></h1></a>
       </div>
      <div className='w-full mr-5 md:mr-0 px-1 lg:w-5/6 _container mt-28 md:mt-auto'>
       <div className='flex flex-col justify-center items-center lg:items-end gap-4 sm:gap-12 py-20'>
       <div className='flex flex-row justify-end gap-4 sm:gap-12'>
        <div className='flex justify-center items-center size-36 sm:size-44  md:size-56 _box card-shine-effect '>
          <div className='flex flex-col justify-center items-center space-y-1 '>
          <h2 className=" text-5xl md:text-6xl">100+</h2>
          <p className='-m-2 text-xs md:text-base'>Completed Projects</p>
          </div>
        </div>
        <div className='flex justify-center items-center size-36 sm:size-44  md:size-56 _box card-shine-effect '>
          <div className='flex flex-col justify-center items-center space-y-1'>
          <h2 className="text-5xl md:text-6xl">50+</h2>
          <p className='-m-2 text-xs md:text-base'>Satisfied Clients</p>
          </div>
        </div>
       </div>
       
       <div className='flex flex-col-reverse w-full lg:flex-row justify-end items-center lg:items-start gap-12 '>
       <div className='flex  justify-center items-center w-full   lg:w-3/6  _brief card-shine-effect_brief p-5 mt-10 md:mt-2 '>
          <div className='flex flex-col justify-center items-start '>
          <h3 className='leading-10 text-start'>Your ultimate design & development partner</h3>
          <p className=' text-start'>Eliminate the hassle of working with designers and developers lacking creativity and expertise. Trust in someone who excels in graphic design, front-end web development, MERN stack development, and UX/UI design to bring your ideas to life.</p>
          <a href="/contact" className='mt-5'>
          <ButtonA text={"Get in tuch"} />
          </a>
          </div>
        </div>
        <div className='flex justify-center items-center size-36 sm:size-44  md:size-56 _box card-shine-effect '>
          <div className='flex flex-col justify-center items-center space-y-1'>
          <h2 className=''><span className="text-6xl">5</span><span className='font-light text-base '>years</span></h2>
          <p className='-m-2 text-xs md:text-base'>Experiance</p>
          </div>
        </div>
        
       </div>
       </div>
       </div>
       </div>

      {/* <div className="text-myWhite text-center mt-2 font-thin italic ">
        Ushan<span className="font-bold underline ">Kamesh</span>
      </div>

      <div className="flex flex-col h-full flex-wrap justify-center items-center ">
        <div className="flex flex-row flex-wrap justify-center items-center ">
          <div className="  m-5 border opacity-75 rounded-[30px] size-48 text-myWhite flex flex-col justify-center items-center">
            <h1 className="text-5xl">100+</h1>
            <p>Completed Projects</p>
          </div>
          <div className=" border m-5 opacity-75 rounded-[30px] size-48 text-myWhite flex flex-col justify-center items-center">
            <h1 className="text-5xl">100+</h1>
            <p>Completed Projects</p>
          </div>
          <div className=" border m-5 opacity-75 rounded-[30px] size-48 text-myWhite flex flex-col justify-center items-center">
            <h1 className="text-5xl">100+</h1>
            <p>Completed Projects</p>
          </div>
        </div>
        <div className="flex flex-col text-myWhite items-left px-10 md:w-[600px]">
          <h3 className="text-left font-semibold text-3xl">Your ultimate design & development partner</h3>
          <p className="text-left text-sm">Eliminate the hassle of working with designers and developers lacking creativity and expertise. Trust in someone who excels in graphic design, front-end web development, MERN stack development, and UX/UI design to bring your ideas to life.</p>
          <a href="/contact" className='mt-5 w-fit'>
          <ButtonA text={"Get in tuch"} />
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default Hero;
