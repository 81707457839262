import React from 'react'
import Dp from '../assets/dp.png';

function Info() {
  return (
    <div className=' flex flex-row justify-center items-baseline px-8 lg:px-4 space-x-1 items-center md:pl-20 md:pt-36'>
      <div className='max-w-[1024px]  '>
        {/* <div className=' opacity-10 my-10'>
        <h1 className='text-myWhite font-medium uppercase'>About Me</h1>
        <p className='bg-myWhite h-0.5 w-1/2'></p>
        </div> */}
      <div className='flex flex-col sm:flex-row items-center w-full pb-8 lg:pb-0  h-fit '>
        <div className=' flex justify-center items-center sm:w-1/2 h-fit'>
            <img src={Dp} alt="my-dp" className=' w-full  sm:-translate-y-  lg:-translate-y-20d' />
        </div>
        <div className='flex flex-col w-auto h-fit  sm:w-1/2 my-10 md:my-0'>
          <h1 className='text-myWhite text-2xl ms:text-3xl lg:text-4xl pb-2 op sm:pb-9 '>Require Advanced Solutions in Design and Technology?</h1>
          <p className='text-myWhite text-xs sm:text-sm lg:text-base text-justify  opacity-75 mb-2'>I am Ushan Kamesh, an engineering undergraduate with a diverse background in design and development. My expertise spans graphic design, UX/UI design, and full-stack development, all supported by a strong foundation in engineering principles. As a co-founder of DevX Team, I am dedicated to driving innovation and excellence in technology.</p>
          <div className=' space-x-6 pt-2'>
            <button className='text-myWhite bg-myRed py-1 px-4 rounded-full hover:bg-opacity-90 font-bold'>Hire me</button>
            <a href="/pdf" target='_blank' className='text-myWhite hover:underline hover:text-opacity-90'>Download CV</a>
          </div>
        </div>
      </div>
      <div className='text-myWhite  grid grid-cols-1 md:grid-cols-2'>
        <button className='bg-myBlack py-4 px-8 my-2  md:my-0 md:m-2 rounded-sm uppercase card-shine-effect'>Undergraduate Engineering Student</button>
        <div className='grid grid-cols-1 sm:grid-cols-2 '>
          <button className='bg-myBlack py-4 mb-2 md:mb-0 md:m-2 px-8 rounded-sm uppercase card-shine-effect'>Graphic Designer</button>
          <button className=' py-4 mb-2 md:mb-0 md:m-2 px-8 rounded-sm  uppercase card-shine-effect bg-myBlack'>UX/UI Designer</button>
        </div>
      </div>
      <div className='text-myWhite  grid grid-cols-1 sm:grid-cols-2'>
        <button className='bg-myBlack py-4 mb-2 md:mb-0 md:m-2 px-8 rounded-sm  uppercase card-shine-effect'>MERN Stack Developer</button>
        <button className='bg-myBlack py-4 mb-10 md:mb-0 md:m-2 px-8 rounded-sm  uppercase card-shine-effect'>Co-Founder, DevX Team</button>
      </div>
      </div>
      {/* <div className=' h-full '>
         
        <img src={sideAbout} alt="" className='w-6 ml-4 hidden sm:block md:ml-6' />
      </div> */}
    </div>
  )
}

export default Info
