import './App.css';
import NavBar from './components/NavBar';
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';

function App() {
  return (
    <div className="App bg-gradient-to-r from-gray-900 to-black">
      {/* Ensure BrowserRouter is used only here */}
      
        <NavBar />  
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
        </Routes>
      
    </div>
  );
}

export default App;
