import React, { useState } from 'react';
import recentProjects from '../data/RecentProjects'; // Import the recent projects data
import uxuiProjects from '../data/UXUI'; // Import the UX/UI projects data
import graphicDesignProjects from '../data/GraphicDesign'; // Import graphic design projects data
import frontEndProjects from '../data/FrontEndDevelopment'; // Import front-end development projects data
import Card from './ProjectCard'; // Import the Card component

const Projects = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  // Define a mapping of activeTab to the respective data
  const tabContentMap = {
    tab1: recentProjects,
    tab2: uxuiProjects,
    tab3: graphicDesignProjects,
    tab4: frontEndProjects,
  };

  const activeTabContent = tabContentMap[activeTab];

  return (
    <div className='flex flex-row justify-center items-baseline px-8 lg:px-4 space-x-1 items-center md:ml-20 mt-36 md:mt-56'>
    <div className="w-full max-w-screen-lg">
      <div className="flex flex-wrap space-x-  mb-4">
        {Object.keys(tabContentMap).map((tabId) => (
          <button
            key={tabId}
            className={`px-4 my-1 py-1 focus:outline-none bg-myWhite bg-opacity-10 border-r border-r-gray-600 ${
              activeTab === tabId
                ? 'border-b-2 border-gray-400 text-myWhite'
                : 'text-gray-500 hover:text-myWhite hover:text-opacity-75'
            }`}
            onClick={() => setActiveTab(tabId)}
            aria-label={`Tab ${tabId}`}
          >
            {tabId === 'tab1' && 'Recent Projects'}
            {tabId === 'tab2' && 'UX/UI'}
            {tabId === 'tab3' && 'Graphic Design'}
            {tabId === 'tab4' && 'Front-End Development'}
          </button>
        ))}
      </div>
        
      <div className="grid grid-cols-1 md:grid-cols-2  gap-12 p-4 bg-myBlack bg-opacity-20 ">
          {activeTabContent?.map((item) => (
            <Card 
              key={item.id} // Use the project id as the key
              id={item.id} // Pass the id prop if needed
              title={item.title} 
              category={item.category} 
              year={item.year} 
            //   longDescription={item.longDescription} 
              thumbnail={item.thumbnail}
            />
          ))}
        </div>
    </div>
  </div>
  );
};

export default Projects;
