import React from "react";
import { Link } from "react-router-dom";
import "./navBar.css";
import iWhatsApp from "../assets/i-whatsApp.svg";
import iFacebook from "../assets/i-facebook.svg";
import iInsta from "../assets/i-insta.svg";
import iEmail from "../assets/i-email.svg";
import iHome from "../assets/i-home.svg";
import iContact from "../assets/i-contact.svg";
import iProject from "../assets/i-project.svg";
import iAbout from "../assets/i-about.svg";

function NavBar() {
  return (
    <div className="md:h-screen bottom-0 md:bottom-auto flex w-full md:w-auto justify-start items-end md:items-center fixed z-40 backdrop:blur-lg">
      <div className="NavBar hidden md:block">
        <div className="_navBar">
          <div className="flex flex-col justify-center items-center _navigation px-2">
            {/* Using Link instead of a tags */}
            <Link to="/" className="rotate-90 py-8 mt-8">
              Home
            </Link>
            <Link to="/projects" className="rotate-90 py-8">
              Projects
            </Link>
            <Link to="/contact" className="rotate-90 py-8">
              Contact
            </Link>
            <Link to="/about" className="rotate-90 py-8">
              About
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center _social px-2">
            <a href="/" className="pt-4">
              <img src={iWhatsApp} alt="WhatsApp" />
            </a>
            <a href="/" className="pt-4">
              <img src={iFacebook} alt="Facebook" />
            </a>
            <a href="/" className="pt-4">
              <img src={iInsta} alt="Instagram" />
            </a>
            <a href="/" className="pt-4 pb-12">
              <img src={iEmail} alt="Email" />
            </a>
          </div>
        </div>
      </div>
      <div className="block md:hidden rounded-t-[50px] bg-black bg-opacity-75 border backdrop-blur-lg border-gray-700 w-full text-myWhite p-4 flex flex-col justify-center items-center space-y-4">
        <div className="flex flex-row space-x-8">
          {/* Mobile view navigation using Link */}
          <Link to="/" className="flex flex-col items-center">
            <img src={iHome} alt="Home" className="size-6 opacity-75 _yellow-glow" />
            <p className="text-sm font-bold text-gray-400">Home</p>
          </Link>
          <Link to="/projects" className="flex flex-col items-center">
            <img src={iProject} alt="Projects" className="size-6 _yellow-glow opacity-75" />
            <p className="text-sm font-bold text-gray-400">Projects</p>
          </Link>
          <Link to="/contact" className="flex flex-col items-center">
            <img src={iContact} alt="Contact" className="size-6 _yellow-glow opacity-75" />
            <p className="text-sm font-bold text-gray-400">Contact</p>
          </Link>
          <Link to="/about" className="flex flex-col items-center">
            <img src={iAbout} alt="About" className="size-6 _yellow-glow opacity-75" />
            <p className="text-sm font-bold text-gray-400">About</p>
          </Link>
        </div>
        <p className="h-2 w-5/6 bg-white rounded-lg opacity-10"></p>
      </div>
    </div>
  );
}

export default NavBar;
