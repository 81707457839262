import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ id, title, category, year, longDescription, thumbnail }) => {
    return (
      <div className="flex flex-col space-y-2 ">
        {thumbnail && (
          <img
            src={thumbnail}
            alt={`${title} thumbnail`}
            className="w-full h-48 object-cover "
          />
        )}
        <div className='text-base font-medium text-myWhite text-opacity-50 flex flex-row justify-between '>
        <p className="">{year}</p>
        <p className=" uppercase">{category}</p>
        </div>
        <hr className=' opacity-30' />
        <div className='flex flex-row justify-between items-center'>
        <h3 className="text-2xl font-normal text-myWhite uppercase">{title}</h3>
        <Link to={'/'}>
        <button className='text-myWhite bg-myRed py-1 px-2'>
            Learn More
            </button></Link>
        </div>
        {/* <p className="text-gray-600">{longDescription}</p> */}
      </div>
  );
};

export default ProjectCard;
