// TimeLines.js

const TimeLines = [
    {
      id: 1,
      name: 'Flowbite Application UI v2.0.0',
      releaseDate: 'January 13th, 2022',
      description: 'Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.'
    },
    {
      id: 2,
      name: 'Flowbite Figma v1.3.0',
      releaseDate: 'December 7th, 2021',
      description: 'All of the pages and components are first designed in Figma and we keep a parity between the two versions even as we update the project.'
    },
    {
      id: 3,
      name: 'Flowbite Library v1.2.2',
      releaseDate: 'December 2nd, 2021',
      description: 'Get started with dozens of web components and interactive elements built on top of Tailwind CSS.'
    },
    {
      id: 4,
      name: 'Item 4',
      releaseDate: 'Date 4',
      description: 'Description for item 4.'
    },
    {
      id: 5,
      name: 'Item 5',
      releaseDate: 'Date 5',
      description: 'Description for item 5.'
    },
    {
      id: 6,
      name: 'Item 6',
      releaseDate: 'Date 6',
      description: 'Description for item 6.'
    },
    {
      id: 7,
      name: 'Item 7',
      releaseDate: 'Date 7',
      description: 'Description for item 7.'
    },
    {
      id: 8,
      name: 'Item 8',
      releaseDate: 'Date 8',
      description: 'Description for item 8.'
    },
    {
      id: 9,
      name: 'Item 9',
      releaseDate: 'Date 9',
      description: 'Description for item 9.'
    },
    {
      id: 10,
      name: 'Item 10',
      releaseDate: 'Date 10',
      description: 'Description for item 10.'
    },
    // Add more items as needed
  ];
  
  export default TimeLines;
  