// src/data/FrontEndDevelopment.js
const frontEndProjects = [
    {
      id: 'frontend1', // Unique ID for the project
      title: 'React Development',
      category: 'Web Development',
      year: 2023,
      longDescription: 'Built a responsive web application using React, implementing hooks for state management and Redux for global state management.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
    {
      id: 'frontend2', // Unique ID for the project
      title: 'Responsive Design',
      category: 'Design',
      year: 2022,
      longDescription: 'Worked on enhancing website responsiveness to ensure a seamless user experience across different devices and screen sizes.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
  ];
  
  export default frontEndProjects;
  