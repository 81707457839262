// src/data/GraphicDesign.js
const graphicDesignProjects = [
    {
      id: 'graphic1', // Unique ID for the project
      title: 'Logo Design',
      category: 'Branding',
      year: 2023,
      longDescription: 'Designed a unique logo for a startup, ensuring it aligns with their brand identity and resonates with their target audience.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
    {
      id: 'graphic2', // Unique ID for the project
      title: 'Branding',
      category: 'Marketing',
      year: 2022,
      longDescription: 'Developed a comprehensive branding strategy for a local business, including visual identity, color schemes, and marketing materials.',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
  ];
  
  export default graphicDesignProjects;
  